<!--<header>
  <div class="container">
    <div class="d-flex flex-row justify-content-between">
      <a class="navbar-brand my-2" routerLink="/home">
        <img src="assets/images/dspace-logo.svg" [attr.alt]="'menu.header.image.logo' | translate"/>
      </a>

      <nav role="navigation" [attr.aria-label]="'nav.user.description' | translate" class="navbar navbar-light navbar-expand-md flex-shrink-0 px-0">
        <ds-themed-search-navbar></ds-themed-search-navbar>
        <ds-lang-switch></ds-lang-switch>
        <ds-context-help-toggle></ds-context-help-toggle>
        <ds-themed-auth-nav-menu></ds-themed-auth-nav-menu>
        <ds-impersonate-navbar></ds-impersonate-navbar>
        <div class="pl-2">
          <button class="navbar-toggler" type="button" (click)="toggleNavbar()"
                  aria-controls="collapsingNav"
                  aria-expanded="false" [attr.aria-label]="'nav.toggle' | translate">
            <span class="navbar-toggler-icon fas fa-bars fa-fw" aria-hidden="true"></span>
          </button>
        </div>
      </nav>
    </div>
  </div>
</header>-->


<header class="main-menu">
         <nav class="top-menu">
            <div class="container d-m-none">
               <div class="menu-cenefa-container">
                  <ul id="menu-cenefa-1" class="menu">
                     <li><a target="_blank" href="https://www.uandes.cl/estudiantes/">Estudiantes</a></li>
                     <li><a target="_blank" href="https://alumni.uandes.cl/?utm_source=sitio_uandes&amp;utm_medium=header">Alumni</a></li>
                     <li><a target="_blank" href="https://www.uandes.cl/academicos/">Académicos</a></li>
                     <li><a target="_blank" href="https://www.uandes.cl/rrii/">Internacional</a></li>
                     <li><a target="_blank" href="https://www.uandes.cl/nosotros/informacion-institucional/vinculacion-con-el-medio/">Vinculación con el Medio</a></li>
                     <li><a target="_blank" href="https://www.uandes.cl/biblioteca/">Biblioteca</a></li>
                     <li><a target="_blank" href="https://www.clinicauandes.cl/?utm_source=home&amp;utm_medium=menu&amp;utm_campaign=clinica&amp;utm_id=clinica">Clínica UANDES</a></li>
                     <li><a target="_blank" href="https://www.ese.cl/?utm_source=home&amp;utm_medium=menu&amp;utm_campaign=ese&amp;utm_id=ese">ESE Business School</a></li>
                  </ul>
               </div>
            </div>
         </nav>
         <nav class="middle-menu">
            <div class="container">
               <a href="/" class="main-logo no-scroll_desktop d-m-none" >
                 <img width="107" height="130" src="assets/images/logo_uandes_macro.webp" alt="Universidad de Los Andes" loading="lazy">
               </a>
               <div class="title_site_per">
                  Repositorio Académico
               </div>
                <nav role="navigation" [attr.aria-label]="'nav.user.description' | translate" class="navbar navbar-light navbar-expand-md flex-shrink-0 px-0">
                  <ds-themed-search-navbar></ds-themed-search-navbar>
                  <ds-lang-switch></ds-lang-switch>
                  <ds-context-help-toggle></ds-context-help-toggle>
                  <ds-themed-auth-nav-menu></ds-themed-auth-nav-menu>
                  <ds-impersonate-navbar></ds-impersonate-navbar>
                  <div class="pl-2">
                    <button class="navbar-toggler" type="button" (click)="toggleNavbar()"
                            aria-controls="collapsingNav"
                            aria-expanded="false" [attr.aria-label]="'nav.toggle' | translate">
                      <span class="navbar-toggler-icon fas fa-bars fa-fw" aria-hidden="true"></span>
                    </button>
                  </div>
                </nav>
            </div>
         </nav>
      </header>
