<!--<footer class="text-lg-start">
  <div *ngIf="showTopFooter" class="top-footer">
    <div class=" container p-4">
      <div class="row">
        <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">
          <h5 class="text-uppercase">Footer Content</h5>
          <ul class="list-unstyled mb-0">
            <li>
              <a routerLink="./" class="">Lorem ipsum</a>
            </li>
            <li>
              <a routerLink="./" class="">Ut facilisis</a>
            </li>
            <li>
              <a routerLink="./" class="">Aenean sit</a>
            </li>
          </ul>
        </div>
        <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">
          <h5 class="text-uppercase">Footer Content</h5>

          <ul class="list-unstyled mb-0">
            <li>
              <a routerLink="./" class="">Suspendisse potenti</a>
            </li>
          </ul>
        </div>
        <div class="col-lg-4 col-md-12 mb-4 mb-md-0">
          <h5 class="text-uppercase">Footer Content</h5>
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iste atque ea quis
            molestias. Fugiat pariatur maxime quis culpa corporis vitae repudiandae aliquam
            voluptatem veniam, est atque cumque eum delectus sint!
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="bottom-footer p-1 d-flex justify-content-center align-items-center text-white">
    <div class="content-container">
      <p class="m-0">
        <a class="text-white"
           href="http://www.dspace.org/">{{ 'footer.link.dspace' | translate}}</a>
        {{ 'footer.copyright' | translate:{year: dateObj | date:'y'} }}
        <a class="text-white"
           href="https://www.lyrasis.org/">{{ 'footer.link.lyrasis' | translate}}</a>
      </p>
      <ul class="footer-info list-unstyled small d-flex justify-content-center mb-0">
        <li>
            <a class="text-white" href="javascript:void(0);"
             (click)="showCookieSettings()">{{ 'footer.link.cookies' | translate}}</a>
        </li>
        <li *ngIf="showPrivacyPolicy">
          <a class="text-white"
             routerLink="info/privacy">{{ 'footer.link.privacy-policy' | translate}}</a>
        </li>
        <li *ngIf="showEndUserAgreement">
          <a class="text-white"
             routerLink="info/end-user-agreement">{{ 'footer.link.end-user-agreement' | translate}}</a>
        </li>
        <li *ngIf="showSendFeedback$ | async">
          <a class="text-white"
             routerLink="info/feedback">{{ 'footer.link.feedback' | translate}}</a>
        </li>
      </ul>
    </div>
  </div>
</footer>-->




<footer class="site-footer">
         <div class="container">
            <div class="footer-contact-container">
               <div class="footer-contact-options">
                  <ul class="contact-options-list">
                     <a class="contacto" href="https://www.uandes.cl/contacto/">Contáctanos</a>
                     <li class="contact-icon-location">Monseñor Álvaro del Portillo 12.455<br>
                        Las Condes, Santiago, Chile
                     </li>
                  </ul>
                  <div class="redes-container">
                     <hr>
                     <div class="social-links-home centered">
                        <a aria-label="Icono red" class="share-facebook" style="color: #E10D17" title="" href="https://www.facebook.com/uandes/" target="_blank"><i class="fab fa-facebook-f" aria-hidden="true"></i></a>
                        <a aria-label="Icono red" class="share-facebook" style="color: #E10D17" href="https://www.instagram.com/uandes/" title="" target="_blank"><i class="fab fa-instagram" aria-hidden="true"></i></a>
                        <a aria-label="Icono red" class="share-facebook" style="color: #E10D17" href="https://www.youtube.com/channel/UCqqj2JVFvy0D0oSvLJuI8uw" title="" target="_blank"><i class="fab fa-youtube" aria-hidden="true"></i></a>
                        <a aria-label="Icono red" class="share-twitter" style="color: #E10D17" href="https://twitter.com/uandes" title="" target="_blank"><i class="fa-brands fa-x-twitter" aria-hidden="true"></i></a>
                        <a aria-label="Icono red" class="share-linkedin" style="color: #E10D17" href="https://cl.linkedin.com/school/uandes/" title="" target="_blank"><i class="fab fa-linkedin" aria-hidden="true"></i></a>
                     </div>
                  </div>
               </div>
               <ul id="menu-footer-links-1" class="footer-nav footer-links-1">
                  <li id="menu-item-153839" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-153839"><a href="https://www.uandes.cl/buses/">Buses de Acercamiento</a></li>
                  <li id="menu-item-149705" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-149705"><a href="http://asp402r.paperless.cl/BoletaUAndes/">Consulta tu Boleta</a></li>
                  <li id="menu-item-149706" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-149706"><a href="https://www.uandes.cl/pagos/">Portal de Pagos</a></li>
                  <li id="menu-item-149702" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-149702"><a href="https://www.uandes.cl/estudiantes/punto-unico/">Punto Único de Atención</a></li>
                  <li id="menu-item-227204" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-227204"><a href="https://www.uandes.cl/accidentes/">En caso de Accidentes</a></li>
                  <li id="menu-item-230773" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-230773"><a href="https://www.uandes.cl/seguridad/">En caso de Hurto</a></li>
                  <li id="menu-item-149704" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-149704"><a href="https://www.uandes.cl/campus-saludable/sana-convivencia/#denuncias">Orientación de Denuncias</a></li>
               </ul>
               <ul id="menu-footer-links-2" class="footer-nav footer-links-2">
                  <li id="menu-item-149710" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-149710"><a href="https://mi.uandes.cl/">Banner miUANDES</a></li>
                  <li id="menu-item-149709" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-149709"><a target="_blank" rel="noopener" href="https://canvas.uandes.cl/">Canvas UANDES</a></li>
                  <li id="menu-item-149707" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-149707"><a href="https://accounts.google.com/signin/v2/identifier?continue=https%3A%2F%2Fmail.google.com%2Fmail%2F&amp;service=mail&amp;hd=miuandes.cl&amp;sacu=1&amp;flowName=GlifWebSignIn&amp;flowEntry=AddSession">Correo MiUANDES</a></li>
                  <li id="menu-item-149708" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-149708"><a href="https://outlook.office.com/mail/inbox/id/AAQkADk5MjJmOTEyLTI4YjQtNDI0My05ODM2LWUzMDkzYjcyODIwNwAQALaeNWF82ohOk6M1V2udcN4%3D">Correo Outlook</a></li>
                  <li id="menu-item-149711" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-149711"><a href="http://pmoodle.uandes.cl/">Moodle</a></li>
                  <li id="menu-item-239050" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-239050"><a href="https://webapp.uandes.cl/nuevacuenta">Crear contraseña Sistemas Académicos</a></li>
               </ul>
               <ul id="menu-footer-links-3" class="footer-nav footer-links-3">
                  <li id="menu-item-149713" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-149713"><a href="https://www.uandes.cl/direccion-de-personas/">Dirección de Personas</a></li>
                  <li id="menu-item-149714" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-149714"><a href="https://www.uandes.cl/comunicaciones-uandes/">Comunicaciones</a></li>
                  <li id="menu-item-149717" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-149717"><a href="https://www.uandes.cl/politicas-de-privacidad/">Políticas de Privacidad</a></li>
                  <li id="menu-item-149712" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-149712"><a href="https://www.uandes.cl/preguntas-frecuentes/">Preguntas Frecuentes</a></li>
                  <li id="menu-item-149715" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-149715"><a href="https://universidadlosandes.trabajando.cl/">Trabaja con Nosotros</a></li>
                  <li id="menu-item-149716" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-149716"><a href="http://www.u-work.org/?utm_source=home&amp;utm_medium=footer&amp;utm_campaign=uwork&amp;utm_id=uwork">Uwork</a></li>
                  <li id="menu-item-261483" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-261483"><a href="https://www.uandes.cl/estudiantes/informacion-academica/certificados/">Validar Certificados</a></li>
               </ul>
            </div>
         </div>
      </footer>
      <footer class="site-colophon">
         <div class="container">
            <div class="logo-acreditacion">
               <img loading="lazy" width="280" height="60" alt="acreditacion icono" src="assets/images/logo-acreditacion-2.webp">
            </div>
            <div class="logo-mas">
               <img loading="lazy" width="217" height="32" alt="ir por mas" src="assets/images/por-mas.webp">
            </div>
         </div>
      </footer>
